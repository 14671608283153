import config from '@/config/env-constants';
import _ from 'lodash';


export const ValidationUtil = {

	isValidURL(str) {
        let regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        return !(!regex.test(str));
    },

	// Only allows a-z, A-z, 0-9 and hyphen
	isAlphaNumeric(string) {
		let result = (/^([a-zA-Z\-0-9])*$/.test(string));
		return result;
	},

	isAlphaNumericWithWhiteSpace(string) {
		let result = (/^([a-zA-Z\-0-9\s])*$/.test(string));
		return result;
	},

	isAlphaNumericWithWhiteSpaceAndComma(string) {
		let result = (/^([a-zA-Z\-0-9\s,])*$/.test(string));
		return result;
	},

	isAlphaWithWhiteSpace(string) {
		let result = (/^([a-zA-Z\-.\s])*$/.test(string));
		return result;
	},

	isAlphaWithWhiteSpaceAndComma(string) {
		let result = (/^([a-zA-Z\-,\s])*$/.test(string));
		return result;
	},

	isCharacter(string) {
		let result = (/^([a-zA-Z])*$/.test(string));
		return result;
	},

	isNumeric(string) {
		let result = (/^([0-9])*$/.test(string));
		return result;
	},

	// Param: dateStrng with "mm-dd-yyyy" format
	isDate(string) {
		let result = (/^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20)\d{2}$/.test(string));
		return result;
	},

	isValidAssetCode(assetTypes, assetCode) {
		let isValid = false;

		if (/^[A-Z]{1,4}[a-hj-km-np-z0-9]{6}$/.test(assetCode)) {
			return true;
		}

		let customAssetTypes = _.filter(assetTypes, o => {
			return o.assetTagging === config.assetTagging.CUSTOM;
		});

		if (!_.isEmpty(customAssetTypes)) {
			for (const assetType of customAssetTypes) {
				let regex = new RegExp(assetType.regex);
				if (regex.test(assetCode)) {
					isValid = true;
					break;
				}
			}
		}
		return isValid;
	},

	isValidAssetCodeDefault(assetCode) {
		return /^[A-Z]{1,4}[a-hj-km-np-z0-9]{6}$/.test(assetCode);
	},

	hasInvalidAsset(assetCodeArr) {
		for (const assetCode of assetCodeArr) {
			let result = /^[A-Z]{1,4}[a-hj-km-np-z0-9]{6}$/.test(assetCode);
			if (!result) {
				return true;
			}
		}
		return false;
	},

	hasExistingAsset(assetCodeArr, assetsObj) {
		for (const assetCode of assetCodeArr) {
			if (assetsObj[assetCode]) {
				return true;
			}
		}
		return false;
	},

	hasAssetWithInTransitStatus(dispatchAssets, assetsObj) {
		let hasInTransitAsset = false;
		for (const assetCode of dispatchAssets) {
			let asset = assetsObj[assetCode];
			if (asset && asset.status === 'In-Transit') {
				hasInTransitAsset = true;
				break;
			}
		}
		return hasInTransitAsset;
	},

	getAssetWithInTransitStatus(dispatchAssets, assetsObj) {
		let assetsInTransit = '';
		for (const assetCode of dispatchAssets) {
			let asset = assetsObj[assetCode];
			if (asset && asset.status === 'In-Transit') {
				assetsInTransit += asset.assetCode + ', ';
			}
		}
		assetsInTransit = assetsInTransit.substring(0, assetsInTransit.length - 2);
		return assetsInTransit;
	},

	hasAssetWithLostStatus(dispatchAssets, assetsObj) {
		let hasLostAsset = false;
		for (const assetCode of dispatchAssets) {
			let asset = assetsObj[assetCode];
			if (asset && asset.status === 'Lost') {
				hasLostAsset = true;
				break;
			}
		}
		return hasLostAsset;
	},

	getAssetWithLostStatus(dispatchAssets, assetsObj) {
		let assetsLost = '';
		for (const assetCode of dispatchAssets) {
			let asset = assetsObj[assetCode];
			if (asset && asset.status === 'Lost') {
				assetsLost += asset.assetCode + ', ';
			}
		}
		assetsLost = assetsLost.substring(0, assetsLost.length - 2);
		return assetsLost;
	},

	isValidPlateNo(plateNo) {
		let result = (/^[a-zA-Z0-9_ ]*$/.test(plateNo));
		return result;
	},

	objectHasField(fieldName, fieldValue, objToCompare) {
		if (objToCompare && !_.isEmpty(objToCompare)) {
			let lowerCaseNames = []

			_.forEach(objToCompare, objectItem => {
				lowerCaseNames.push(objectItem[fieldName].toLowerCase());
			});

			let lowerCaseFieldValue = fieldValue.toLowerCase();
			if (lowerCaseNames.includes(lowerCaseFieldValue)) {
				return true;
			}
		}
		return false;
	},

	arrayHasValue(optionArr, fieldName, valueToCompare) {
		let found = false;
		if (optionArr && optionArr.length > 0) {
			found = optionArr.some(function (el) {
				if (el.value === null) {
					return false;
				}
				return el.value[fieldName] === valueToCompare;
			});
		}
		return found;
	},

	arrayHasValueDirectField(optionArr, fieldName, valueToCompare) {
		let found = false;
		if (optionArr && optionArr.length > 0) {
			found = optionArr.some(function (el) {
				return el[fieldName] === valueToCompare;
			});
		}
		return found;
	},

	// return empty string for null param
	removeExcessWhiteSpace(stringValue) {
		let trimmedStr = '';
		if (stringValue) {
			trimmedStr = stringValue.trim().replace(/\s\s+/g, ' ');
		}
		return trimmedStr;
	},

	arrayContains(array, letters) {
		let status = false;
		letters.forEach(letter => {
			array.forEach(item => {
				if (item.includes(letter)) status = letter;
			});
		});
		return status;
	},

	hasDuplicates(array, key) {
		let values = _.map(array, key);
		let uniqValues = _.uniq(values);
		return values.length > uniqValues.length;
	},

	getDuplicates(array, key) {
		let duplicates = [];
		let arrayPerKey = _.groupBy(array, key);

		_.forEach(arrayPerKey, (value, _key) => {
			if (value.length > 1) {
				duplicates.push(_key);
			}
		});

		return duplicates;
	},
}
